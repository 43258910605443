$(document).ready(function () {
    // Focus on either the mobile or the verification code input field when logging in.
    if ($('#VerificationCode_Code').length)
        $('#VerificationCode_Code').focus();
    else
        $('#Mobile').focus();

    $("#btnResendCode").click(function () {
        var serviceUrl = "/Umbraco/Api/AccountApi/ResendMobileVerificationCode?phoneNumber=" + $("#Mobile").val();

        var request = $.ajax({
            url: serviceUrl,
            type: "GET",
        });

        request.done(function (data) {
            $("#verifyMobileResendMessage").show();
            $("#verifyMobileResendMessage").html("Kode sendt på nytt til " + data);

            setTimeout(function () {
                $('#verifyMobileResendMessage').hide();
            }, 5000);
        });
    });
});